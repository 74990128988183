/* global VXConfig */
import FluxEventEmitter        from '../FluxEventEmitter';
import assign                                   from 'object-assign';
import {doFetch, existsApiCookie, urlWithQuery} from '../../utils/CommonUtils';
import Constants                                from '../Constants';
import Dispatcher              from '../Dispatcher';
import DailyBonusActionTypes   from '../actions/Types/DailyBonusActionTypes';
import ActorActionCreator      from '../actions/ActorActionCreator';
import DailyBonusActionCreator from '../actions/DailyBonusActionCreator';
import Flux                    from "../Flux";

const VXQL_URL          = VXConfig.vxqlUrl;
const VXQL_ACCESS_TOKEN = VXConfig.vxqlAccessToken;
const LANG              = VXConfig.language;
const VIDEO_SORT_ORDERS = Constants.Vxql.Videos.SortOrders;
const BONUS_SERVICE_URL = VXConfig.services.bonusCode.url;
const VXONE_SESSION_ID  = VXConfig.vxoneSessionId;

let _dailybonusData    = {};
let _contestsData      = {};
let eventConfigPromise = null;
const heatMapsPromise  = {};

/**
 *
 * @param {string} query
 * @param {Object} variables
 * @param {Object} queryParam
 * @returns {Promise}
 */
function doVxqlRequest(query, variables = null, queryParam = {}) {
	if (!VXQL_ACCESS_TOKEN) {
		return Promise.reject(new Error('Missing Vxql-Token'));
	}

	const queryObject = {};
	queryObject.query = query;

    const queryString = window.location.search;
    const urlParams   = new URLSearchParams(queryString);
    let qmdate = null;
    let qmday  = null;

	if (variables) {
		queryObject.variables = variables;
	}

	const init = {
		headers: {'Authorization': 'Bearer ' + VXQL_ACCESS_TOKEN},
	};

    const params = {...queryParam, language: LANG};

    if (urlParams.get('qmdate')) {
        params.qmdate = urlParams.get('qmdate');
    }

    if (urlParams.get('qmDay')) {
        params.qmday = urlParams.get('qmday');
    }

	const requestUrl = urlWithQuery(VXQL_URL, params);

	return doFetch(requestUrl, queryObject, Constants.HttpMethods.POST, existsApiCookie(), init);
}

/**
 *
 * @param {string} query
 * @param {Object} variables
 * @param {Object} queryParam
 * @returns {Promise}
 */
function doBonusCodeRequest(query, variables = null, queryParam = {}) {
	const queryObject = {};
	queryObject.query = query;

	if (variables) {
		queryObject.variables = variables;
	}

	const init = {
		headers: {'Authorization': 'Bearer ' + VXONE_SESSION_ID},
	};

	const requestUrl = urlWithQuery(BONUS_SERVICE_URL, {
		...queryParam,
		language: LANG,
	});

	return doFetch(requestUrl, queryObject, Constants.HttpMethods.POST, false, init);
}

function getGuestInitData(callback) {
	const query = `
	query {
		guest {
			vouchers {
				type
				amount
				actorId
			}
		}
	}`;

	doVxqlRequest(query).then(callback);
}

function initVxEsFreePreviewInit(decline = false) {
	const query = `
	mutation ($decline: Boolean!){
        guest {
            initVXESFreePreview(decline: $decline)
        }
	}`;

	doVxqlRequest(query, {decline});
}

/**
 *
 * @param {Function} callback
 */
function getUnreadMessagesCount(callback) {
	const query = `
	query{
		guest {
		    messages {
		      unreadCount
		    }
		}
	}`;

	doVxqlRequest(query).then(callback);
}

/**
 *
 * @param {Function} callback
 */
function getBoughtGiftsForGuest(callback) {
	const query = `
	query {
		guest {
			boughtMessengerGifts {
				gift {
					id
				}
				quantity
			}
		}
	}`;

	doVxqlRequest(query).then(callback);
}

/**
 *
 * @param {Function} callback
 */
function getMessengerGifts(callback) {
	const query = `
	query {
		messenger {
			gifts {
				id
				name
				price {currency value symbol}
				imageUrl
				active
				vip
				categoryId
				translations {language name value}
			}
		}
	}`;

	doVxqlRequest(query).then(callback);
}

/**
 *
 * @param {Function} callback
 */
function getMessengerToyControls(callback) {
	const query = `
	query {
		messenger {
			toyControls {
				id
				name
				price {currency value symbol}
				imageUrl
				active
				vip
				categoryId
				translations {language name value}
				duration
				displayName
				random
			}
		}
	}`;

	doVxqlRequest(query).then(callback);
}

/**
 *
 * @param {int} amount
 * @param {Function} callback
 */
function getOnlineModels(amount, callback) {
	const query = `
	query($amount: Int!){
		models(first:$amount, filter: {online: true}){
            id,
            name,
            profile {
                age
            },
            profileLink: linkVX(internal: true),
            avatar(ageRating: 16){
                url(size: h1920)
            }
		}
	}
	`;

	doVxqlRequest(query, {amount: amount}).then(callback);
}

/**
 *
 * @param {int} amount
 * @param {object} filter
 * @param {Function} callback
 */
function getFilteredModels(amount, filter, callback) {
	const args  = {amount: amount, filter: filter};
	const query = `
	query($amount: Int!, $filter: ModelsFilter = null ){
		models(first:$amount, filter: $filter){
            id,
            name,
            online,
			profileLink: linkVX(internal: true),
			star,
            avatar(ageRating: 16){
                url(size: w160)
			},
			profile {
				age
				threeWords
			}

		}
	}
	`;

	doVxqlRequest(query, args).then(callback);
}

function getPreferencedModel(filter, callback) {
	const query = `
	query($first: Int, $filter: ModelFilter = null){
		models_v3(first:$first, filter: $filter, order: guest_preferences){
			items {
				id
            	name
            	online
				linkVX(internal: true)
            	avatar(ageRating: 16){
            	    url(size: w640)
				},
				profile {
					age
				}
			}
		}
	}
	`;
	doVxqlRequest(query, {first: 3, filter}).then(callback);
}

/**
 *
 * @param {int} first
 * @param {int} last
 * @param {int} offset
 * @param {string} order
 * @param {object} filter
 * @param {string} preset
 */
function getModels(first, last, offset, order, filter, preset, isV3, onlineFilter) {
	if (isV3) {
		return getModelsV3(first, last, offset, order, filter, onlineFilter);
	}

	const args  = {first, last, offset, order, filter, preset};
	const query = `
	query($first: Int, $last: Int, $offset: Int, $order: ModelsOrder, $filter: ModelsFilter = null, $preset: String ){
		models_v2(first:$first, last:$last, offset:$offset, order:$order, filter: $filter, preset:$preset){
			total
			items {
				id
            name
            online
			linkVX(internal: true)
			star
			registeredSince
			sedcard(first: 3, ageRating: 16) {
				id
				url(size: w640)
				urlSmall: url(size: w320)
			}
            avatar(ageRating: 16){
                url(size: w640)
			},
			profile {
				age
				threeWords
				languages
			}
			modelGuestRelation {
				isFavorite
			  }
			isTopAmateur
			chat {
				prices {
				  videoChat {
					value
					currency
				  }
				  voyeurChat {
					value
					currency
				  }
				  ticketShow {
					value
					currency
				  }
				  singleChat {
					value
					currency
				  }
				  singleC2CChat {
				    value
				    currency
				  }
				}
				features {
				  livePreview
				  hd
				  dildocontrol
				}
				online
			}
			isOnlineMobileVideocall
			}
		}
	}
	`;

	return doVxqlRequest(query, args);
}

/**
 *
 * @param {int} first
 * @param {int} last
 * @param {int} offset
 * @param {string} order
 * @param {object} filter
 */
function getModelsV3(first, last, offset, order, filter, onlineFilter) {
	let onlineFilterQuery = ``;
	if (onlineFilter) {
		onlineFilterQuery = `
		onlineTotal: models_v3(first:$first, last:$last, offset:$offset, order:$order, filter: $onlineFilter){
			total
			items {
				name
				online
			}
		}`;
	}
	const args  = {first, last, offset, order, filter, onlineFilter};
	const query = `
	query($first: Int, $last: Int, $offset: Int, $order: ModelsOrder, $filter: ModelFilter = null, $onlineFilter: ModelFilter = null ){
		${onlineFilterQuery}
		models_v3(first:$first, last:$last, offset:$offset, order:$order, filter: $filter){
			total
			items {
				id
            name
            online
			linkVX(internal: true)
			star
			registeredSince
			sedcard(first: 3, ageRating: 16) {
				id
				url(size: w640)
				urlSmall: url(size: w320)
			}
            avatar(ageRating: 16){
                url(size: w640)
			},
			profile {
				age
				threeWords
				languages
			}
			modelGuestRelation {
				isFavorite
			  }
			isTopAmateur
			chat {
				prices {
				  videoChat {
					value
					currency
				  }
				  voyeurChat {
					value
					currency
				  }
				  ticketShow {
					value
					currency
				  }
				  singleChat {
					value
					currency
				  }
				  singleC2CChat {
				      value
                      currency
                  }
				}
				features {
				  livePreview
				  hd
				  dildocontrol
				}
				online
			}
			isOnlineMobileVideocall
			}
		}
	}
	`;

	return doVxqlRequest(query, args);
}

/**
 *
 * @param {int} amount
 * @param {object} filter
 * @param {Function} callback
 * @param {string} order
 * @param {array} modelIds
 */
function getFilteredVideos(amount, filter, callback, order, modelIds, ids) {
	const args  = {amount: amount, filter: filter, order: order, modelIds: modelIds, ids};
	const query = `
	query($amount: Int!, $filter: [VideoTypeFilter!] = null, $imageSizes: [PhotoImageSizeEnum!] = [w320, w640, w1280], $order: ModelVideosOrder = null, $modelIds: [Int!] = null, $ids: [Int!] = null){
		videos_v2(first:$amount, filter: {types:$filter, modelIds: $modelIds, ids: $ids}, order:$order){
			items {
				id
				title
				duration
				ageRating
				vip
				isVip30Offer
				free
				new
				camRecording
				classic
				guestInfo {
					bought
					gifted
					flatrate
					liked
				}
				price {
					value
					currency
				}
				basePrice {
					value
					currency
				}
				preview {
					id
					aspectRatio
					images(size: $imageSizes, placeholder: true) {
						size
						url
					}
				}
				previewThumbnails(first: 100) {
					id
					aspectRatio
					images(size: $imageSizes, placeholder: true) {
						size
						url
					}
				}
				rating {
					likes
				}
				model {
					name
					linkVX(internal: true)
					id
					avatar(ageRating: 16, useFallback: true){
						url(size: w60)
					}
				}
				contestInfo {
					place
				}
				linkVX(internal: true)
			}
			total
		}
	}
	`;

	doVxqlRequest(query, args).then(callback);
}

const processGlobalSearchQuery = `
	videos {
	 type
	 count
	 link
	}
	photos {
	 type
	 count
	 link
	}
	mediathek {
	 type
	 count
	 link
	}
	blog {
	 type
	 count
	 link
	}
	`;

const processGlobalSearchExtendQuery = `
	videos {
	 type
	 count
	 link
	 items {
		id
		title
		duration
		ageRating
		vip
		free
		new
		camRecording
		guestInfo {
			bought
			gifted
			flatrate
			liked
		}
		price {
			value
			currency
		}
		basePrice {
			value
			currency
		}
		preview {
			id
			aspectRatio
			images(size: [w320], placeholder: true) {
				size
				url
			}
		}
		previewThumbnails(first: 100) {
			id
			aspectRatio
			images(size: [w320], placeholder: true) {
				size
				url
			}
		}
		rating {
			likes
		}
		model {
			name
			linkVX(internal: true)
			id
		}
		contestInfo {
			place
		}
		linkVX(internal: true)
	}

	}
	photos {
	 type
	 count
	 link
	 items {
		 id
		 title
		 description
		 photosCount
		 linkVX(internal: true)
		 preview {
			id
			aspectRatio
			images(size: [w320], placeholder: true) {
				size
				url
			}
		}
		model {
			name
			linkVX(internal: true)
			id
		}
	 }
	}
	mediathek {
	 type
	 count
	 link
	 items {
		 id
		 title
		 description
		 duration
		 previewPicture
		 linkVX(internal: true)
		 models {
			 total
			 items {
				 name
				 linkVX(internal: true)
				 id
			 }
		 }
	 }
	}
	blog {
	 type
	 count
	 link
	 items {
		 id
		 title
		 subTitle
		 previewPicture
		 linkVX(internal: true)
	 }
	}
`;

/**
 *
 * @param {string} searchPattern
 * @param {bool} isExtended
 * @param {Function} callback
 */
function processGlobalSearch(searchPattern, isExtended, callback) {
	const query = `
	query($searchPattern: String!){
		search {
			global(search: $searchPattern) {
				searchPattern
				proposals
				actors {
					type
					count
					link
					items {
						type
						model {
							name
							linkVX(internal: true)
							chat {
								online
								mobileVideoCall
							}
							avatar {
								url(size: b320)
								aspectRatio
							}
						}
					}
				}
				${isExtended ? processGlobalSearchExtendQuery : processGlobalSearchQuery}
			}
		}
	}`;

	doVxqlRequest(query, {searchPattern: searchPattern}).then(callback);
}

/**
 *
 * @param {Function} callback
 */
function processSuggestions(callback) {
	const query = `
	query{
		search {
			lastSearchRequests
			suggestions {
				name
				targetUrl
			}
		}
	}`;

	doVxqlRequest(query).then(callback);
}

/**
 *
 * @param {string} searchPattern
 * @param {Function} callback
 * @param {int} first
 * @param {int} offset
 * @param {?string} order
 * @param {Object} filter
 */
function processActorSearch(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
	const query = `
	query($searchPattern: String!, $first: Int, $offset: Int, $order: SearchOrder, $filter: SearchFilter){
		search{
			models(search: $searchPattern, first: $first, offset: $offset, order: $order, filter: $filter){
				type
				total
				items{
					model{
						id
						name
						star
						sedcard(first: 2) {
							id
                            url(size: w320)
							urlSmall: url(size: w320)
                        }
                        avatar(ageRating: 16, useFallback: true){
                        	id
                            url(size: w320)
                        }
						chat {
							online
							prices {
                                videoChat {
                                    value
                                }
							}
							features {
                                livePreview
                                hd
                                audio
                                dildocontrol
                            }
						}
						profile {
                            age
                            languages
                        }
						linkVX(internal: true)
						birthdayText
					}
				}
			}
		}
	}
	`;

	doVxqlRequest(query, {searchPattern: searchPattern, first: first, offset: offset, order: order, filter: filter}).then(callback);
}

/**
 *
 * @param {string} searchPattern
 * @param {Function} callback
 * @param {int} first
 * @param {int} offset
 * @param {?string} order
 * @param {Object} filter
 */
function processVideoSearch(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
	const query = `
	query($searchPattern: String!, $first: Int, $offset: Int, $order: SearchOrder, $filter: SearchFilter, $imageSizes: [PhotoImageSizeEnum!] = [w320, w640, w1280]){
		search{
			videos(search: $searchPattern, first: $first, offset: $offset, order: $order, filter: $filter){
				type
				total
				items{
					video{
						id
						title
						ageRating
						isBought
						vip
						classic
						price{
                            value
                            currency
                        }
                        basePrice{
                            value
                        }
						discount
						guestInfo {
							bought
							gifted
							flatrate
							liked
						}
						preview {
							id
							aspectRatio
							images(size: $imageSizes, placeholder: true) {
								size
								url
							}
						}
						previewThumbnails(first: 100) {
							id
							aspectRatio
							images(size: $imageSizes, placeholder: true) {
								size
								url
							}
						}
						linkVX(internal: true)
						duration
						rating {
							likes
						}
						model {
							id
                            name
                            linkVX(internal: true)
                        }
					}
				}
			}
		}
	}
	`;

	doVxqlRequest(query, {searchPattern: searchPattern, first: first, offset: offset, order: order, filter: filter}).then(callback);
}

/**
 *
 * @param {string} searchPattern
 * @param {Function} callback
 * @param {int} first
 * @param {int} offset
 * @param {?string} order
 * @param {Object} filter
 */
function processGallerySearch(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
	const query = `
	query($searchPattern: String!, $first: Int, $offset: Int, $order: SearchOrder, $filter: SearchFilter){
		search{
			galleries(search: $searchPattern, first: $first, offset: $offset, order: $order, filter: $filter){
				type
                total
                items {
                    gallery {
                        id
                        title
                        description
                        photosCount
                        linkVX(internal: true)
                        model {
                            name
                        }
                        previews {
                            id
                            url(size: w640)
                            height
                            width
                        }
                    }
                }
            }
		}
	}
	`;

	doVxqlRequest(query, {searchPattern: searchPattern, first: first, offset: offset, order: order, filter: filter}).then(callback);
}

/**
 *
 * @param {string} searchPattern
 * @param {Function} callback
 * @param {int} first
 * @param {int} offset
 * @param {?string} order
 * @param {Object} filter
 */
function processMediathekSearch(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
	const query = `
	query($searchPattern: String!, $first: Int, $offset: Int, $order: SearchOrder, $filter: SearchFilter){
		search{
			mediathek(search: $searchPattern, first: $first, offset: $offset, order: $order, filter: $filter){
				type
                total
                items {
                    mediathekVideo {
                        id
                        title
                        fsk
                        linkVX(internal: true)
                        previewPicture
                        duration
                    }
                }
            }
		}
	}
	`;

	doVxqlRequest(query, {searchPattern: searchPattern, first: first, offset: offset, order: order, filter: filter}).then(callback);
}

/**
 *
 * @param {string} searchPattern
 * @param {Function} callback
 * @param {int} first
 * @param {int} offset
 * @param {?string} order
 * @param {Object} filter
 */
function processBlogSearch(searchPattern, callback, first = 15, offset = 0, order = null, filter = null) {
	const query = `
	query($searchPattern: String!, $first: Int, $offset: Int, $order: SearchOrder, $filter: SearchFilter){
		search{
			blog(search: $searchPattern, first: $first, offset: $offset, order: $order, filter: $filter){
				type
                total
                items {
                    blogPost {
                        id
                        title
                        subTitle
                        linkVX(internal: true)
                        date
                        previewPicture
                        featured
                        excerpt
                    }
				}
			}
		}
	}
	`;

	doVxqlRequest(query, {searchPattern: searchPattern, first: first, offset: offset, order: order, filter: filter}).then(callback);
}

function storeSearchPattern(searchPattern, callback) {
	const query = `
	mutation ($searchPattern: String!){
        globalSearch {
            storeSearchPattern(searchPattern: $searchPattern)
        }
	}
`;

	doVxqlRequest(query, {searchPattern: searchPattern}).then(callback);
}

function getVxqlContests(activeIsSpecial, callback) {
	const query = `
		query ($activeIsSpecial: Boolean!) {
		  contests {
		    current {
		      type
		      previewUrl(activeIsSpecial: $activeIsSpecial)
		      linkVX(internal:true)
		    }
		    passed(first:2) {
		      title
		      type
		      linkVX(internal:true)
		      previewUrl
		    }
		  }
		}
	`;
	doVxqlRequest(query, {activeIsSpecial: activeIsSpecial})
		.then((result) => {
			_contestsData = result.data.contests;
			return result;
		})
		.then(callback);
}

/**
 * @param {string} order
 * @param {number} first
 * @param {function} callback
 */
async function getCollection(order, first, callback) {
	const query = `
	query($order: CollectionOrder, $first: Int){
		collections(order: $order, first: $first) {
		    id
		    name
		    modelCount
		    linkVX
			imageId
			imageUrl
			label
	    }
	}
	`;

	return doVxqlRequest(query, {order, first}).then(callback);
}

/**
 * @param {string} order
 * @param {number} first
 * @param {function} callback
 */
async function getCollectionsV2(args, callback) {
	const query = `
	query($collectionNames: [CollectionName], $firstModels: Int, $hiddenCategories: Boolean, $order: CollectionOrder){
		collections_v2(collectionNames: $collectionNames, hiddenCategories: $hiddenCategories, order: $order) {
		    name
            imageId
            imageUrl
            label
            linkVX
            modelCount
			models(first: $firstModels) {
				items {
				id
				name
				linkVX(internal: true)
				avatar(useFallback: true){
		                id
		                url(size: w320)
		                bigUrl: url(size: w640)
						height
						width
		        }
				profile {
					age
					languages
					country
				}
				online
			}
 		 }
	    }
	}
	`;

	return doVxqlRequest(query, {...args}).then(callback);
}

/**
 * @param {array} collectionSlugs
 * @param {int} first
 * @param {int} offset
 * @param {ModelsFilter} filter
 */
async function getActorsByCollectionSlug(collectionSlugs, first = 15, offset = 0, filter) {
	const query = `
	query($collectionSlugs: [String], $first: Int, $offset: Int, $filter: ModelsFilter){
		collections(collectionSlugs: $collectionSlugs) {
		    id
		    name
		    slug
		    modelCount
			footerText
			teaserText
			label
		    linkVX
            models(first: $first, offset: $offset, order: online_ranking, filter: $filter){
	            total
	            items{
	                id
	                name
	                star
					sedcard(first: 2) {
						id
		                url(size: w320)
						urlSmall: url(size: w320)
		            }
		            avatar(ageRating: 12, useFallback: true){
		                id
		                url(size: w320)
		            }
					chat {
						online
						prices {
		                    videoChat {
		                        value
		                    }
						}
						features {
		                    livePreview
		                    hd
		                    audio
		                    dildocontrol
		                }
					}
					profile {
		                age
		                languages
		            }
					linkVX(internal: true)
					birthdayText
		        }
		    }
	    }
	}
	`;

	return doVxqlRequest(query, {collectionSlugs: collectionSlugs, first: first, offset: offset, filter: filter});
}

/**
 * @param {array} collectionSlugs
 * @param {int} first
 * @param {int} offset
 * @param {ModelsFilter} filter
 */
async function getActorsTotalByCollectionSlug(collectionSlugs, first = 15, offset = 0, filter) {
	const query = `
	query($collectionSlugs: [String], $first: Int, $offset: Int, $filter: ModelsFilter){
		collections(collectionSlugs: $collectionSlugs) {
            models(first: $first, offset: $offset, order: online_ranking, filter: $filter) {
                total
                items{
                    id
                }
            }
	    }
	}
	`;

	return doVxqlRequest(query, {collectionSlugs: collectionSlugs, first: first, offset: offset, filter: filter});
}

/**
 * @param {String} date
 * @param {Function} callback
 */
function getDailyBonuses(date, callback) {
	const query = `
		fragment cVideo on Video {
			id, title, ageRating, duration(format: min), preview { url(size:w640) }, model { id, name },
			price {value, currency}, basePrice {value, currency}, dailyBonusPrice {value, currency} linkVX(internal: true), hidden
		}

		fragment cGallery on Gallery {
			id, title, ageRating, preview { url(size:w320) }, model { id, name }, photos: previews (for: "365") {id, ageRating, url(size:w320)},
			price {value, currency}, basePrice {value, currency}, dailyBonusPrice { value, currency } linkVX(internal: true), photosCount, hidden
		}

		fragment cWallpaper on Wallpaper {
			id, model {id, name, aliases, profileLink: linkVX(internal: true) }, hd, fourK, mobile, wPreview: preview
		}

		fragment cGift on MessengerGift {
			id, name, imageUrl, translations { language, name, value }
		}

		query {
			dailyBonus {
				previous: bonus(day:yesterday) {
					date
					offer
					conditions {name, value}
					effect { name, value, unit, code }
					quantity
					purchased
					free
					content {
						... cVideo
						... cGallery
						... cWallpaper
						... cGift
					}
				},
				current: bonus(day:today) {
					date,
					offer
					conditions {name, value }
					purchased
					effect { name, value, unit, code }
					quantity
					free
					content {
						... cVideo
						... cGallery
						... cWallpaper
						... cGift
					}
				},
				next: bonus(day:tomorrow) {
					offer, purchased, free
				}
			}
		}
	`;

	doVxqlRequest(query, null, date ? {qmdate: date} : {})
		.then((result) => {
			_dailybonusData[date] = result.data.dailyBonus;
			return result;
		})
		.then(callback);
}

/**
 *
 * @param {int} albumId
 * @param {array} tagIds
 * @param {Function} callback
 */

function addTagToAlbum(albumId, tagIds, callback) {
	const query = `
	mutation SUGGEST_TAGS($tagIds: [Int]!, $albumId: Int!) {
		 guest {
		    suggestTag{
                addTags(tagIds: $tagIds, albumId: $albumId)
            }
        }
	}`;

	doVxqlRequest(query, {albumId: albumId, tagIds: tagIds}).then(callback);
}


/**
 *
 * @param {int} featureId
 * @param {string} rating
 * @param {string} text
 * @param {string} url
 * @param {Function} callback
 */
function sendFeatureFeedback(featureId, rating, text, url, callback) {
	const query = `
	mutation SendFeatureFeedback($featureId: Int!, $rating: String!, $text: String!, $url: String!) {
		guest {
			sendFeatureFeedback(featureId: $featureId, rating: $rating, text: $text, url: $url)
		}
	}`;

	doVxqlRequest(query, {featureId, rating, text, url}).then(callback);
}

/**
 *
 * @param {int} id
 * @param {function} callback
 */

function addVideoLike(id, callback) {
	const query = `
	mutation ($id: Int!) {
		guest {
			likeVideo(id: $id) {
				id
            }
		}
	}`;
	doVxqlRequest(query, {id}).then(callback);
}

/**
 *
 * @param {int} id
 * @param {function} callback
 */

function addVideoDislike(id, callback) {
	const query = `
	mutation ($id: Int!) {
		guest {
			dislikeVideo(id: $id) {
				id
            }
		}
	}`;
	doVxqlRequest(query, {id}).then(callback);
}

/**
 *
 * @param {int} id
 */

function addAlbumView(id) {
	const query = `
	mutation ($id: Int!) {
		guest {
			viewAlbum(id: $id)
		}
	}`;
	doVxqlRequest(query, {id});
}

/**
 *
 * @param {int} id
 * @param {function} callback
 */

function addGalleryLike(id, callback) {
	const query = `
	mutation ($id: Int!) {
		guest {
			likeGallery(id: $id) {
				id
            }
		}
	}`;
	doVxqlRequest(query, {id}).then(callback);
}

/**
 *
 * @param {int} id
 * @param {function} callback
 */

function addGalleryDislike(id, callback) {
	const query = `
	mutation ($id: Int!) {
		guest {
			dislikeGallery(id: $id) {
				id
            }
		}
	}`;
	doVxqlRequest(query, {id}).then(callback);
}

function getGuestVideoTime(chatId, callback) {
	const query = `
	query ($chatId: Int!) {
		guest {
			chat(chatId: $chatId) {
				videoTime
            }
		}
	}`;
	doVxqlRequest(query, {chatId}).then(callback);
}


/**
 *
 * @param {Function} callback
 */
function getBonusModels(callback) {
	const dateFrom = new Date();
	dateFrom.setDate(1);

	const variables = {
		dateFrom: dateFrom.toISOString(),
		dateTo:   new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0).toISOString(),
	};

	const query = `
    query($dateFrom:Date, $dateTo:Date) {
        dailyBonus {
            models(dateFrom:$dateFrom, dateTo:$dateTo) {
                id
                name
                avatar {
                    url(size:h640)
                }
                profileLink: linkVX(internal: true)
                profile {
                    showText(language:"${LANG}"), age,
                }
                online
            }
        }
    }`;

	doVxqlRequest(query, variables).then(callback);
}

/**
 *
 * @param {Function} callback
 * @param {Number} first
 * @param {Number} offset
 * @param {String|null} dateFrom
 * @param {String|null} dateTo
 */
function getConsumedOffers(callback, first = 31, offset = 0, dateFrom = null, dateTo = null) {
	const query = `
    query getConsumedOffers($first:Int, $offset:Int, $dateFrom:Date, $dateTo:Date) {
      dailyBonus {
        consumed {
          totalCount
          offers(first:$first, offset:$offset, dateFrom:$dateFrom, dateTo:$dateTo) {
            date
            offer
            conditions {
              name
              value
            }
            effect {
              name
              value
              unit
              code
            }
            quantity
            content {
              ... on Video {
                title
                linkVX
                price: basePrice {
                  value
                  currency
                }
                dailyBonusPrice {
                  value
                  currency
                }
                model {
                  name
                  profileLink: linkVX(internal: true)
                }
              }
              ... on Gallery {
                title
                linkVX
                price {
                  value
                  currency
                }
                dailyBonusPrice {
                  value
                  currency
                }
                model {
                  name
                  profileLink: linkVX(internal: true)
                }
              }
              ... on MessengerGift {
                name
                giftPrice: price {
                  value
                  currency
                }
              }
            }
          }
        }
      }
    }`;
	doVxqlRequest(query, {first, offset, dateFrom, dateTo}).then(callback);
}

/**
 * @param {number} actorId
 * @param {number} count
 * @param {number} offset
 * @param {string[]} orders
 * @param {boolean} includeGuestRating
 * @param {string[]} tags
 * @param {boolean} onlyFreeContent
 * @param {boolean} fetchAllVideosCount
 * @param {boolean} onlyVXSelectContent
 * @param {boolean} onlyClassicContent
 * @param {string[]} imageSizes
 * @return {Promise}
 */
function getFilteredActorVideos(
	actorId,
	count               = 4,
	offset              = 0,
	orders              = VIDEO_SORT_ORDERS.NEWEST,
	includeGuestRating  = false,
	tags                = [],
	onlyFreeContent     = false,
	fetchAllVideosCount = false,
	onlyVXSelectContent = false,
	onlyClassicContent  = false,
	imageSizes
) {
	const sortOrderVariables = {};

	for (const key of Object.keys(VIDEO_SORT_ORDERS)) {
		const order               = VIDEO_SORT_ORDERS[key];
		sortOrderVariables[order] = orders.indexOf(order) !== -1;
	}

	let filter        = null;
	let models        = '';
	let model         = '';
	let actorIdString = '';

	if (Array.isArray(tags) && tags.length > 0) {
		filter = {tags};
	}
	if (onlyFreeContent) {
		if (!filter) {
			filter = {};
		}
		filter.types = [{free: true}];
	}

	if (onlyClassicContent) {
		if (!filter) {
			filter = {};
		}
		if (!filter.types) {
			filter.types = [{classic: true}];
		} else {
			filter.types[0].classic = true;
		}
	}

	if (onlyVXSelectContent) {
		if (!filter) {
			filter = {};
		}
		if (!filter.types) {
			filter.types = [{vxselect: true}];
		} else {
			filter.types[0].vxselect = true;
		}
	}

	const videoFilter = [
		'newest: videos_v2(first: $count, offset: $offset, filter: $filter, order: newest) @include(if: $newest) {...videosResultFields}',
		'mostExpensive: videos_v2(first: $count, offset: $offset, filter: $filter, order: mostExpensive) @include(if: $mostExpensive) {...videosResultFields}',
		'cheapest: videos_v2(first: $count, offset: $offset, filter: $filter, order: cheapest) @include(if: $cheapest) {...videosResultFields}',
		'longest: videos_v2(first: $count, offset: $offset, filter: $filter, order: longest) @include(if: $longest) {...videosResultFields}',
		'shortest: videos_v2(first: $count, offset: $offset, filter: $filter, order: shortest) @include(if: $shortest) {...videosResultFields}',
		'bestSelling: videos_v2(first: $count, offset: $offset, filter: $filter, order: bestSelling) @include(if: $bestSelling) {...videosResultFields}',
		'topRated: videos_v2(first: $count, offset: $offset, filter: $filter, order: topRated) @include(if: $topRated) {...videosResultFields}',
		'totalCount: videos_v2(first: 0) @include(if: $allVideosCount) {total}',
	];

	if (Array.isArray(actorId)) {
		models  = `models(filter: {ids: [${actorId}]}) { id ${videoFilter.map(vFilter => vFilter)}}`;
		actorId = 1;
	}

	if (Number.isInteger(actorId)) {
		model         = `model(id: $actorId) { ${videoFilter.map(vFilter => vFilter)}}`;
		actorIdString = `$actorId: Int,`;
	}

	const query = `
	query ActorVideos(
		${actorIdString}
		$count: Int = 4,
		$offset: Int = 0,
		$includeGuestRating: Boolean = false,
		$imageSizes: [PhotoImageSizeEnum!] = [w320, w640, w1280],
		$filter: ModelVideosFilter = null,
		$allVideosCount: Boolean = false,
		$newest: Boolean = false,
		$mostExpensive: Boolean = false,
		$cheapest: Boolean = false,
		$longest: Boolean = false,
		$shortest: Boolean = false,
		$bestSelling: Boolean = false,
		$topRated: Boolean = false
	) {
        ${model}
		${models}
	}
	fragment videosResultFields on VideosResult {
		total
		items {
			id
	        title
	        duration
	        ageRating
	        vip
	        isVip30Offer
	        free
	        new
	        camRecording
	        classic
	        guestInfo {
	            bought
	            gifted
	            liked @include(if: $includeGuestRating)
	            flatrate
	        }
	        price {
	            value
	            currency
	        }
	        basePrice {
	            value
	            currency
	        }
	        preview {
	            id
	            aspectRatio
	            images(size: $imageSizes, placeholder: true) {
	                size
	                url
	            }
	        }
	        previewThumbnails(first: 100) {
	            id
	            aspectRatio
	            images(size: $imageSizes, placeholder: true) {
	                size
	                url
	            }
	        }
	        rating {
	            likes
	        }
	        model {
	            id
	            name
				linkVX(internal: true)
				id
	        }
	        contestInfo {
	            place
	        }
	        linkVX(internal: true)
        }
	}`;

	const variables = {
		actorId,
		count,
		offset,
		...sortOrderVariables,
		includeGuestRating,
		imageSizes,
		allVideosCount: fetchAllVideosCount,
	};

	if (filter) {
		variables.filter = filter;
	}
	return doVxqlRequest(query, variables);
}


/**
 * @param {Object} filter
 * @param {string} order
 * @param {number} count
 * @param {number} offset
 * @return {Promise}
 */
function getVideosFromVXQL(
	filter,
	order,
	count,
	offset
) {

	const query = `
	query Videos(
		$count: Int = 10,
		$offset: Int = 0,
		$imageSizes: [PhotoImageSizeEnum!] = [w320, w640, w1280],
		$filter: VideosFilter = null,
		$order: ModelVideosOrder = null
	) {

        videos: videos_v2(first: $count, offset: $offset, filter: $filter, order: $order) {
            ...videosResultFields
        }

	}

	fragment videosResultFields on VideosResult {
		total
		items {
			id
	        title
	        duration
	        ageRating
	        vip
	        isVip30Offer
	        free
	        new
	        camRecording
	        classic
	        guestInfo {
	            bought
	            gifted
	            liked
	            flatrate
	        }
	        price {
	            value
	            currency
	        }
	        basePrice {
	            value
	            currency
	        }
	        preview {
	            id
	            aspectRatio
	            images(size: $imageSizes, placeholder: true) {
	                size
	                url
	            }
	        }
	        previewThumbnails(first: 100) {
	            id
	            aspectRatio
	            images(size: $imageSizes, placeholder: true) {
	                size
	                url
	            }
	        }
	        rating {
	            likes
	        }
	        model {
	            id
	            name
	            linkVX(internal: true)
	        }
	        contestInfo {
	            place
	        }
	        linkVX(internal: true)
	        schema
			url(profile: hls)
        }
	}`;

	const variables = {
		count,
		order,
		offset,
		filter,
	};

	return doVxqlRequest(query, variables);
}

/**
 * @param {Object} filter
 * @param {string} order
 * @param {number} count
 * @param {number} offset
 * @return {Promise}
 */
function getPhotoAlbumsFromVXQL(
	filter,
	order,
	count,
	offset
) {

	const query = `
	query Galleries(
		$count: Int = 10,
		$offset: Int = 0,
		$imageSizes: [PhotoImageSizeEnum!] = [w320, w640, w1280],
		$filter: GalleriesFilter = null,
		$order: ModelGalleriesOrder = null
	) {

        galleries(first: $count, offset: $offset, filter: $filter, order: $order) {
            ...galleriesResultFields
        }

	}

	fragment galleriesResultFields on GalleriesResult {
		total
		items {
			id
	        title
	        description
	        ageRating
	        free
	        guestInfo {
	            bought
	            gifted
	            liked
	            flatrate
	        }
	        price {
	            value
	            currency
	        }
	        basePrice {
	            value
	            currency
	        }
	        photosCount
	        preview {
	            id
	            aspectRatio
	            images(size: $imageSizes, placeholder: true) {
	                size
	                url
	            }
	        }
	        previews {
	            id
	            width
	            height
	            aspectRatio
	            orientation
	            images(size: $imageSizes, placeholder: true) {
	                size
	                url
	            }
	        }
	        rating {
	            likes
	        }
	        model {
	            id
	            name
	            linkVX(internal: true)
	        }
	        linkVX(internal: true)
			isVipContent
        }
	}`;

	const variables = {
		count,
		order,
		offset,
		filter,
	};

	return doVxqlRequest(query, variables);
}

/**
 * @param {number} count
 * @param {number} offset
 * @return {Promise}
 */
function getTVSeriesFromVXQL(
	count,
	offset
) {

	const query = `
	query TVSeries(
		$count: Int = 10,
		$offset: Int = 0
	) {
		tv {
            series: series(first: $count, offset: $offset) {
                ...tvSeriesResultFields
            }
        }

	}

	fragment tvSeriesResultFields on TVSeriesResult {
		total
		items {
			id
	        title
	        episodeCount
	        linkVX(internal: true)
	        previewImgUrl
        }
	}`;

	const variables = {
		count,
		offset,
	};

	return doVxqlRequest(query, variables);
}

/**
 * @param {string} hashId
 * @param {number} count
 * @return {Promise}
 */
function getTVMediathekVideoRecommendationsByHashIdFromVXQL(
	hashId,
	count
) {

	const query = `
	query TVMediathekVideoRecommendations(
		$count: Int = 5,
		$hashId: String!
	) {
		tv {
            mediathekVideo(hashId: $hashId) {
                recommendations {
                    mediathekVideos(first: $count) {
                        ...mediathekVideoFields
                    }
                }
            }
        }
	}

	fragment mediathekVideoFields on MediathekVideo {
		id
	    title
	    fsk
		duration
	    linkVX(internal: true)
	    previewPicture
	}`;

	const variables = {
		count,
		hashId,
	};

	return doVxqlRequest(query, variables);
}

function getMediathekVideosFromVXQL(filter, count, offset) {

	const query = `
	query MediathekVideos(
		$count: Int = 10,
		$offset: Int = 0,
		$filter: MediathekVideosFilter = null,
	) {

        tv {
            mediathekVideos(first: $count, offset: $offset, filter: $filter) {
                ...mediathekVideosResultFields
            }
        }

	}

	fragment mediathekVideosResultFields on TVMediathekVideosResult {
		total
		items {
			id
	        title
	        fsk
	        duration
	        linkVX(internal: true)
	        previewPicture
        }
	}`;

	const variables = {
		count,
		offset,
		filter,
	};

	return doVxqlRequest(query, variables);
}

/**
 * @param {number} actorId
 * @return {Promise}
 */
function getActorVideoTags(actorId) {
	const query = `
	query ActorVideoTags($actorId: Int) {
        model(id: $actorId) {
            videoTags
        }
	}`;

	const variables = {
		actorId,
	};

	return doVxqlRequest(query, variables);
}

/**
 * @param {Number} actorId
 * @returns {Promise}
 */
function getActorChatRatings(actorId) {
	const query = `
    query ActorChatRatingsQuery($actorId: Int!) {
        model(id:$actorId) {
            chatRatings { lastNinetyDays { countRatings, satisfactionRate } }
        }
    }`;
	return doVxqlRequest(query, {actorId});
}

/**
 * @param actorId
 * @param first
 * @return {Promise}
 */
function getActorRecommendedModels(actorId, first = 4, isExtended = false) {
	let fragment = `
		profile {
			age
		}`;

	if (isExtended) {
		fragment = `
		profile {
			age
			languages
		}
		chat {
			online
			prices {
				videoChat {
					value
				}
			}
			features {
				livePreview
				hd
				audio
				dildocontrol
			}
		}
		sedcard(first: 3, ageRating: 16) {
			id
			url(size: w640)
			urlSmall: url(size: w320)
		}`;
	}


	const query = `
    query RecommendedModels($actorId: Int!, $first: Int) {
        model(id:$actorId) {
            recommendations {
				models(first:$first) {
					id
					name
					avatar { url(size:b320) }
					linkVX(internal: true)
					online
					${fragment}
				}
		    }
        }
    }`;
	return doVxqlRequest(query, {actorId, first});
}

/**
 * @param {Number} actorId
 * @param {String} imageSize
 * @param {Boolean} placeholder
 * @param {String} durationFormat
 * @return {Promise}
 */
function _loadActorInterviews(
	actorId,
	imageSize      = Constants.Interviews.IMAGE_SIZE_720,
	placeholder    = true,
	durationFormat = Constants.Interviews.DURATION_SEC
) {
	const query = `
		query ActorInterviewsQuery(
			$actorId: Int!
			$imageSize: TVMediaLibraryPictureSizeEnum
			$placeholder: Boolean
			$durationFormat: VideoDurationFormatEnum
		) {
			model(id:$actorId) {
				interviews {
					id
					title
					model {
						id
						name linkVX(internal:true)
					}
					previewUrl(size: $imageSize, placeholder: $placeholder)
					description
					ageRating
					vip
					duration(format: $durationFormat)
					videoUrl
					restriction {
						restricted
						reasons
					}
				}
			}
		}
	`;

	return doVxqlRequest(query, {
		actorId,
		imageSize,
		placeholder,
		durationFormat,
	});
}

/**
 * @param {number} actorId
 * @param {number} countFromSameActor
 * @param {number} countFromDifferentActor
 * @return {Promise}
 */
function getVideoRecommendationsByActorId(actorId, countFromSameActor, countFromDifferentActor) {
	const query = `
	query VideoRecommendationsByActorId(
		$actorId: Int,
		$countFromSameActor: Int!,
		$countFromDifferentActor: Int!,
		$fromSameActor: Boolean!,
		$fromDifferentActor: Boolean!,
		$imageSizes: [PhotoImageSizeEnum!] = [w320, w640, w1280]
	) {
		model(id: $actorId) {
			recommendations {
				fromSameActor: videos(sameModel: true, first: $countFromSameActor) @include(if: $fromSameActor) {
					...videoRecommendationFields
				}
				fromDifferentActor: videos(sameModel: false, first: $countFromDifferentActor) @include(if: $fromDifferentActor) {
					...videoRecommendationFields
				}
			}
		}
	}

	fragment videoRecommendationFields on Video {
		id
		title
		duration
		ageRating
		vip
		free
		new
		camRecording
		guestInfo {
			bought
			gifted
			flatrate
		}
		price {
			value
			currency
		}
		basePrice {
			value
			currency
		}
		preview {
			id
			aspectRatio
			images(size: $imageSizes, placeholder: true) {
				size
				url
			}
		}
		previewThumbnails(first: 100) {
			id
			aspectRatio
			images(size: $imageSizes, placeholder: true) {
				size
				url
			}
		}
		rating {
			likes
		}
		model {
			name
			linkVX(internal: true)
		}
		contestInfo {
			place
		}
		linkVX(internal: true)
	}
	`;

	const variables = {
		actorId,
		countFromSameActor,
		countFromDifferentActor,
		fromSameActor:      countFromSameActor > 0,
		fromDifferentActor: countFromDifferentActor > 0,
	};

	return doVxqlRequest(query, variables);
}

/**
 * @param {number} albumId
 * @param {number} countFromSameActor
 * @param {number} countFromDifferentActor
 * @return {Promise}
 */
function getVideoRecommendationsByAlbumId(albumId, countFromSameActor, countFromDifferentActor) {
	const query = `
	query VideoRecommendationsByAlbumId(
		$albumId: Int,
		$countFromSameActor: Int!,
		$countFromDifferentActor: Int!,
		$fromSameActor: Boolean!,
		$fromDifferentActor: Boolean!,
		$imageSizes: [PhotoImageSizeEnum!] = [w320, w640, w1280]
	) {
		video(id: $albumId) {
			recommendations {
				fromSameActor: videos(sameModel: true, first: $countFromSameActor) @include(if: $fromSameActor) {
					...videoRecommendationFields
				}
				fromDifferentActor: videos(sameModel: false, first: $countFromDifferentActor) @include(if: $fromDifferentActor) {
					...videoRecommendationFields
				}
			}
		}
	}

	fragment videoRecommendationFields on Video {
		id
		title
		duration
		ageRating
		vip
		free
		new
		camRecording
		classic
		guestInfo {
			bought
			gifted
			flatrate
		}
		price {
			value
			currency
		}
		basePrice {
			value
			currency
		}
		preview {
			id
			aspectRatio
			images(size: $imageSizes, placeholder: true) {
				size
				url
			}
		}
		previewThumbnails(first: 100) {
			id
			aspectRatio
			images(size: $imageSizes, placeholder: true) {
				size
				url
			}
		}
		rating {
			likes
		}
		model {
			name
			linkVX(internal: true)
		}
		contestInfo {
			place
		}
		linkVX(internal: true)
	}
	`;

	const variables = {
		albumId,
		countFromSameActor,
		countFromDifferentActor,
		fromSameActor:      countFromSameActor > 0,
		fromDifferentActor: countFromDifferentActor > 0,
	};

	return doVxqlRequest(query, variables);
}

/**
 *
 * @param {number} first
 * @param {number} offset
 * @returns {Promise}
 */
function getConversionChatActors(first = 30, offset = 0) {
	const query = `
	query($first: Int!, $offset: Int!) {
		models(first: $first, order: ranking, filter: {conversionChat: true, online: true}, offset: $offset) {
			id
			name
			star
			sedcard(first: 2) {
				id
                url(size: w320)
				urlSmall: url(size: w320)
            }
            avatar(ageRating: 16, useFallback: true){
                id
                url(size: w320)
            }
			chat {
				online
				prices {
                    videoChat {
                        value
                    }
				}
				features {
                    livePreview
                    hd
                    audio
                    dildocontrol
                }
			}
			profile {
                age
                languages
            }
			linkVX(internal: true)
        }
    }
	`;

	return doVxqlRequest(query, {first: first, offset: offset});
}


/**
 * @param {function} callback
 * @param {number} actorId
 */
function loadFreeChatDuration(callback, actorId) {
	const query = `
	query($actorId: Int!) {
		guest {
			chatFeatures {
				freeChatDuration(modelId: $actorId, accumulated: true)
			}
		}
	}
	`;

	doVxqlRequest(query, {actorId: actorId}).then((result) => callback(result, actorId));
}

function getChannels(callback, blocked = null) {
	const query = `
query ($first: Int, $offset: Int, $filter: MessengerChannelsFilterInput) {
  guest {
    messengerChannels(first: $first, offset: $offset, filter: $filter) {
      items {
        channelId
        actorId
        guestId
        unreadCount
        blocked
        model {
          name
        }
      }
      total
    }
  }
}
	`;

	doVxqlRequest(query, {filter: {blocked: blocked}, first: 10, offset: 0}).then((result) => callback(result));
}

/**
 * @param {function} callback
 * @param {boolean} feedOnly
 */
function getFavoriteModels(callback, feedOnly = false) {
	const query = `
	query($feedOnly: Boolean) {
		guest {
			pinnedModels(feedOnly: $feedOnly) {
			  id
			  name
			  online
			  avatar {
			    url(size: w140)
			  }
			}
		}
	}
	`;

	doVxqlRequest(query, {feedOnly}).then((result) => callback(result));
}

/**
 *
 * @param first
 * @param feedOnly
 * @param callback
 */
function getRecommendedModels(first, feedOnly, callback) {
	const query = `
	query($first: Int, $feedOnly: Boolean) {
		guest {
			modelRecommendations(first: $first, feedOnly: $feedOnly) {
			  id
			  name
			  online
			  profile {
			    age
			  }
			  modelGuestRelation {
			    isFavorite
			  }
			  favoriteCount
			  avatar {
			    url(size: w140)
			  }
			  cover {
			    url(size:w640)
			  }
			}
		}
	}
	`;

	doVxqlRequest(query, {first, feedOnly}).then((result) => callback(result));
}

/**
 * @param {function} callback
 */
function getBenefits(callback) {
	const query = `
	query {
		guest {
		  benefits {
			voucher {
			  id
			  guestId
			  type
			  amount
			  validFrom
			  validTo
			  actors {
				id
				online
				name
				linkVX(internal: true)
				avatar {
					url(size: w160)
				  }
			  }
			}
			spinwheelSpins
			messengerGiftCount
			freeMessagesCount
			privateShowTickets {
				actor {
					id
					name
					linkVX(internal: true)
					online
					avatar {
						url(size: w160)
					}
				}
			}
			chatShowTickets {
			  show {
				id
				start
				end
			  }
			  actor {
				  id
				  name
				  linkVX(internal: true)
				  online
				  avatar {
					  url(size: w160)
				  }
			  }
			}
		  }
		}
	  }
	`;

	doVxqlRequest(query, {}).then((result) => callback(result));
}

/**
 * @param {function} callback
 */
function getMyVisitXSidebarData(callback) {
	const query = `
	query {
		guest {
			name
			avatar {
				url(size: w160)
			}
			vip
			boughtVideosCount
			boughtPhotosCount
			privateCollectionCount
			pinnedFavoriteCount
			pinnedFavoriteOnlineCount
			videochatCount
			balanceFormatted
		}
	}
	`;

	doVxqlRequest(query, {}).then((result) => callback(result));
}

/**
 *
 * @param {number} actorId
 * @returns {Promise}
 */
function getActorHeatMaps(actorId) {
	if (!heatMapsPromise[actorId]) {

		const query              = `
		query ActorHeatMaps($actorId: Int) {
			model(id: $actorId) {
				actorName: name
				heatMaps {
					heatMap {
						date
						activity { level, start, end }
					}
					hoursToBeOnline
					memo
				}
			}
		}`;
		heatMapsPromise[actorId] = doVxqlRequest(query, {actorId});
		heatMapsPromise[actorId].then((result) => {
			delete heatMapsPromise[actorId];
			return result;
		});
	}
	return heatMapsPromise[actorId];
}

/**
 *
 * @param {bool}     mobile
 * @param {string}   routeName
 * @param {array}    routeArgs
 * @param {function} callback
 */
function loadBanner(mobile, routeName, routeArgs, callback) {

	const params = {
		mobile,
		routeName,
		routeArgs,
	};

	const query = `
		query Banner($mobile: Boolean!, $routeName: String!, $routeArgs: [RouteArg!]) {
			banner(mobile: $mobile, routeName: $routeName, routeArgs: $routeArgs) {
				imagePath
				onClickUrl
				onClickType
				relatedActorId
				containerClassSuffix
			}
		}`;

	doVxqlRequest(query, params).then(callback);
}

function addLiveReaction(callback, referenceType, referenceId, imageId, message, data) {
	const query = `
mutation($referenceType: String!, $referenceId: Int!, $imageId: Int!, $message: String, $data: String) {
	guest {
		liveReactions {
			addReaction(referenceType: $referenceType, referenceId: $referenceId, imageId: $imageId, message: $message, data: $data)
		}
	}
}`;

	doVxqlRequest(query, {referenceType, referenceId, imageId, message, data}).then(callback);
}

const CHANGE_EVENTS = {
	DailyBonusLoaded: 'DailyBonusLoaded',
};

/**
 * @param {number} clashId
 * @param {Function} callback
 */
function getUserActivityData(activityId, callback) {
	const args  = {id: activityId};
	const query = `
	query coc($id: Int!) {
		specialEvents {
			userActivity {
			  eventById(id: $id) {
				id
				name
				start
				end
				rewardClaimEnd
				activities {
				  id
				  name
				  points
				  vipBonus
				  limitPerDay
				  faqTitle
				  faqLink
				  faqLinkTitle
				}
				challenges {
				  points
				  name
				  description
				  fullfilled
				  date
				  link
				  linkTitle
				}
				rewards {
				  id
				  type
				  requiredPoints
				  info
				  value
				}
				guest {
					id
					points
					rewards {
					  id
					  requiredPoints
					  type
					  value
					  redeemed
					  info
					  video {
						linkVX(internal: true)
						title
						duration
						model {
							name
						}
						preview {
							images(size: w320) {
								url
							}
						}
					  }
					}
				}
			  }
			}
		  }
    }`;
	doVxqlRequest(query, args).then((result) => callback(result));
}

/**
 *
 * @param {int} id
 * @param {Function} callback
 */
function setUserActivityReward(eventId, rewardId, callback) {
	const query = `
	mutation($eventId: Int!, $rewardId: Int!) {
		guest {
			userActivity {
			  claimReward(eventId: $eventId, rewardId: $rewardId) {
				id
				type
				value
				requiredPoints
				info
			  }
			}
		  }
	}`;

	doVxqlRequest(query, {eventId, rewardId}).then((result) => callback(result));

}

const bonusCodeQuery = `
  code
  config {
    containsTopUpBenefit
    validTo
    conditions {
      requireMobileAuth
      vip
    }
    benefits {
      __typename
      ... on BenefitBonus {
        id
        name
        description
        bonusFlat
        bonusTopUpPercent
        bonusTopUpFlat
        bonusTopUpStaggering {
          from
          amount
        }
        bonusTopUpStaggeringPercent {
          from
          amount
        }
      }
      ... on BenefitBonusCoins {
        id
        name
        description
        bonusFlatCoins
        bonusTopUpPercentCoins
        bonusTopUpFlatCoins
        bonusTopUpCoinsStaggering {
          from
          amount
        }
        bonusTopUpCoinsStaggeringPercent {
          from
          amount
        }
      }
      ... on BenefitBonusCredits {
        id
        name
        description
        voucherConfigId
        bonusFlatCredits
        bonusTopUpPercentCredits
        bonusTopUpFlatCredits
        bonusTopUpCreditsStaggering {
          from
          amount
        }
      }
      ... on BenefitVIPAbo {
        id
        name
        description
        daysVIPAbo
        actorId
      }
      ... on BenefitPremiumAbo {
        id
        name
        description
        daysPremiumAbo
      }
      ... on BenefitVoucherVideo {
        id
        name
        description
        voucherConfigId
        voucherVideoAmount
        voucherVideoAmountStaggering {
          from
          amount
        }
      }
      ... on BenefitVoucherPhoto {
        id
        name
        description
        voucherConfigId
        voucherPhotoAmount
        voucherPhotoAmountStaggering {
          from
          amount
        }
      }
      ... on BenefitFreeMinutes {
        id
        name
        description
        actorId
        voucherConfigId
        freeMinutesCount
        freeMinutesCountStaggering {
          from
          amount
        }
      }
      ... on BenefitFreeMessages {
        id
        name
        description
        voucherConfigId
        actorId
        freeMessagesCount
        freeMessagesCountStaggering {
          from
          amount
        }
      }
      ... on BenefitMedia {
        id
        name
        description
        albumId
        albumIdStaggering {
          from
          id
        }
      }
      ... on BenefitActorAbo {
        id
        name
        description
        daysActorAbo
        daysActorAboStaggering {
          from
          amount
        }
        aboId
      }
      ... on BenefitMessengerGifts {
        id
        name
        description
        messengerGiftsId
        messengerGiftsAmount
        messengerGiftsStaggering {
          from
          amount
          id
        }
      }
      ... on BenefitLiveShow {
        id
        name
        description
        liveShowActorId
        liveShowActorIdStaggering {
          from
          amount
          id
        }
      }
    }
  }`;

/**
 *
 * @param {string} code
 * @param {Function} callback
 */
function getBonusCode(code, callback) {
	const query = `
	query GUEST_CODE($code: String!) {
		canRedeem(code: $code) {
			success
			reason
		}

		bonusCode(code: $code) {
			` + bonusCodeQuery + `
		}
	  }`;

	doBonusCodeRequest(query, {code}).then((result) => callback(result));
}

/**
 *
 * @param {string} code
 * @param {Function} callback
 */
function getRemainingBonusCodes(callback) {
	const query = `
	query {
		remainingBonusCodes {
			remainingCount
			validTo
			` + bonusCodeQuery + `
		}
	  }`;

	doBonusCodeRequest(query, {}).then((result) => callback(result));
}

/**
 * @param {number} clashId
 * @param {Function} callback
 */
function getEasterEvent(easterId, callback) {
	const args  = {id: easterId};
	const query = `
	query easter($id: Int!) {
			specialEvents {
			  easterEgg {
				eventById(id: $id) {
				  id
				  name
				  start
				  end
				  rewardClaimEnd
				  guest {
					collectedEggs
					rewardHistory {
					  reward {
						type
						info
						id
						requiredEggs
						isUpTo
						value
					  }
					  redeemed
					  video {
						id
						title
						linkVX(internal: true)
						url(profile:mp4_1)
						model {
							name
						  }
						duration
						preview {
							id
							aspectRatio
							images(size: [w320], placeholder: true) {
								size
								url
							}
						}
					  }
					}
				  }
				  rewards {
					type
					info
					id
					value
					requiredEggs
					hidden
					isUpTo
				  }
				}
			  }
			}

    }`;
	doVxqlRequest(query, args).then((result) => callback(result));
}

function getVip30ValueHistory(callback) {
	const query = `
query {
  specialEvents {
    vip30 {
      videosValueHistory {
        month
        year
        value
      }
    }
  }
}
	`;
	doVxqlRequest(query, {}).then((result) => callback(result));
}

/**
 * @param {number} actorId
 * @param {Function} callback
 */
function getProfileLiveShowTicket(actorId, callback) {
	const args  = {id: actorId};
	const query = `
	query coc($id: Int!) {
		model(id: $id) {
			ticketChatList {
				id
				actorId
				actorName
				actorLink
				price {
					value
					currency
					symbol
				}
				title {
                    value
                    language
                  }
				description_v2 {
					value
					language
				}
				postBuyInfo {
					value
					language
				}
				sedcards {
					url(size: w640)
					aspectRatio
				}
				sedcard {
					url(size: w640)
					aspectRatio
				}
				actorAvatar {
					url(size: w320)
				 }
				imageUrl
				start
				end
				isBought
				isFree
				canBuy
				hasStarted
				bonusCode
			}
		}
    }`;
	doVxqlRequest(query, args).then((result) => {
		let data = [];
		if (result.data && result.data.model.ticketChatList && result.data.model.ticketChatList.length > 0) {
			data = result.data.model.ticketChatList;
		}
		callback(data);
	});
}

function buyChatTicket(ticket, creationType = Flux.Constants.TicketCreationType.CHAT_TICKET, callback) {
	const args  = {...ticket, creationType};
	const query = `
	mutation($id: Int, $modelId: Int, $start: Int, $promoCode: String, $creationType: ChatTicketCreationType!) {
	  guest {
		buyChatTicket(
		  id: $id
		  creationType: $creationType,
		  modelId: $modelId,
		  start: $start,
		  promoCode: $promoCode
		) {
		  success
		  errorCode
		  ticket {
			id
		  }
		  show {
		  	id
		  	actorName
		  	price {
			  value
			  currency
		  	}
		  	isBought
		  	isFree
		  }
		}
	  }
	}`;
	doVxqlRequest(query, args).then((result) => {
		let data;
		if (result.data && result.data.guest && result.data.guest.buyChatTicket) {
			data = result.data.guest.buyChatTicket;
		}
		callback(data);
	});
}

function removeChatTicket(ticket, callback) {
	const args  = {...ticket};
	const query = `
	mutation($id: Int) {
	  guest {
		removeChatTicket(
		  id: $id
		) {
		  success
		  errorCode
		  ticket {
			id
		  }
		  show {
		  	id
		  	actorName
		  	price {
			  value
			  currency
		  	}
		  	isBought
		  	isFree
		  }
		}
	  }
	}`;
	doVxqlRequest(query, args).then((result) => {
		let data;
		if (result?.data?.guest?.removeChatTicket) {
			data = result.data.guest.removeChatTicket;
		}
		callback(data);
	});
}

/**
 * @param {number}   first
 * @param {number}   offset
 * @param {object}   filter
 * @param {String}   order
 * @param {Function} callback
 */
function getMessengerContent(first = 10, offset = 0, filter = null, order = '', callback) {
	const args  = {first, offset, filter, order};
	const query = `
	query messengerContent($first: Int!, $offset: Int!, $filter: MessengerContentFilterInput!, $order: String) {
		guest {
		  id
		  messengerContent(first: $first, offset: $offset, filter: $filter, order: $order) {
			items {
			  messageId
			  text
			  date
			  model {
				name
				linkVX(internal: true)
				id
			  }
			  media {
				type
				origSrc
				src
				srcSet
				width
				height
				duration
				previewPicture
			  }
			}
			total
		  }
		}
	  }`;

	doVxqlRequest(query, args).then((result) => callback(result));
}

function getLateNightShow(callback) {
	const query = `
		query lateNightShow {
		    tv {
                lateNightShow{
                    header {
                        headerImg16Url
                        headerImg18Url
                    }
	                events {
				        id
				        preHeadlineDe
				        preHeadlineEn
				        headlineDe
				        headlineEn
				        textDe
				        textEn
				        secondHeadlineDe
				        secondHeadlineEn
				        secondTextDe
				        secondTextEn
				        begin
				        end
				        actors{
				          imageUrl
				          actor{
				            id
				            name
				            linkVX
				          }
				        }
	                }
               }
            }
		}
	`;
	doVxqlRequest(query, {}).then((result) => callback(result));
}

function getCurrentProgram(premium, callback) {
	const query = `
		query($premium: Boolean!) {
		  tv {
		    currentProgram(premium: $premium) {
		      title
		      previewPicture
		      start
		    }
		  }
		}
	`;
	doVxqlRequest(query, {premium}).then((result) => callback(result));
}

function getCamShows({endDateFrom, order, startDateTo, first, internal, callback}) {
	const query = `
		query ($endDateFrom: String!, $startDateTo: String!, $first: Int, $order: String, $internal: Boolean) {
		    chatShows {
					getShowList(endDateFrom: $endDateFrom, startDateTo: $startDateTo, first: $first, pfmId: 1502, order: $order, status: "ok", internal: $internal) {
						total
						shows {
						  id
						  name
						  actorId
						  actorName
						  sedcards {
						    url(size: w640)
							aspectRatio
						  }
						  sedcard {
						    url(size: w640)
							aspectRatio
						  }
						  title {
		                    value
		                    language
                          }
						  actorAvatar {
							url(size: w320)
						  }
						  imageUrl
						  ump {
						    url(size: w640)
						    aspectRatio
						  }
						  actorOnline
						  isFeatured
						  isAutoFeatured
						  isInternal
						  price {
							  value
							  currency
							  symbol
						  }
						  actorLink(internal: true)
						  start
						  end
						  isBought
						  isFree
						  canBuy
						  bonusCode
						  hasStarted
						}
					}
            }
		}
	`;
	doVxqlRequest(query, {endDateFrom, order, startDateTo, first, internal}).then((result) => callback(result));
}


function startAnonymousVoicecallChat(callback, actorId, accessToken) {
	const args = {actorId: actorId, accessToken: accessToken};

	const query = `
	mutation($accessToken: String!, $actorId: Int!) {
		messenger {
			startAnonymousVoicecallChat(accessToken: $accessToken, actorId: $actorId) {
				resultCode
				server
				clientId
			}
		}
    }`;

	doVxqlRequest(query, args).then((result) => callback(result));
}

function getWelcomeClips(first, fsk, callback) {
	const args = {first, fsk};

	const query = `
	query ($first: Int!, $fsk: Int!) {
		welcomeClipRotator(first: $first, fsk: $fsk)  {
			id
			p1080: url(profile:mp4_hd)
			p720:  url(profile:mp4_1)
			p480:  url(profile:mp4_2)
			p360:  url(profile:mp4_3)
			p240:  url(profile:mp4_4)
			hls:   url(profile:hls)
			preview {
			  url(size:w640)
			}
			vtt {
			  vttUrl
			  imageUrls
			}
			schema
			model {
			  name
			  id
			  linkVX
			}
		  }
    }
	`;

	doVxqlRequest(query, args).then((result) => callback(result));
}


function getVideoStars(first = 100) {
	const query = `
query ($first: Int) {
  videoStars(first: $first) {
    actorId
    actorName
    age
    profileLink
    videoCount
    newVideoCount
    previewPicture
    online
  }
}`;

	return doVxqlRequest(query, {first: first});
}

function claimActorGiftCalendarReward(actorId, qmDay) {
	const query = `
mutation ($actorId: Int!, $qmday: Int) {
  guest {
    actorGiftCalendar {
      claimReward(actorId: $actorId, qmday: $qmday) {
        type
        value
        claimed
        redeemed
      }
    }
  }
}`;

	return doVxqlRequest(query, {actorId: actorId, qmday: qmDay});
}

function touchChannel(actorId) {
	const query = `
mutation ($actorId: Int!) {
  guest {
    touchMessengerChannel(actorId: $actorId)
    }
  }
`;

	return doVxqlRequest(query, {actorId: actorId});
}

function getLegalTexts(filter, first, offset, callback) {
	const args = {filter, first, offset};

	const query = `
	query ($filter: LegalTextsFilter!, $first: Int, $offset: Int) {
		legalTexts(filter: $filter, first: $first, offset: $offset) {
			items {
			  id
			  type
			  product
			  version
			  language
			  html
			}
		  }
    }
	`;

	doVxqlRequest(query, args).then((result) => callback(result));
}

function getSeo(tags, callback) {
	const args = {tags};
	const query = `
	query($tags: [String!]!) {
		seo {
			texts {
			  fromTags(tags: $tags) {
				h1
				h2
				seoTitle
				seoDescription
				freeText
				freeTextShort
				breadcrumb
				slug
			  }
			}
		}
    }
	`;

	doVxqlRequest(query, args).then((result) => callback(result));
}


function getSpinwheelInfo(callback, type) {
    const variables = type ? {type} : {};

    let additionalQuery = ``;

    switch (type) {
        case Constants.Spinwheel.Types.goldrausch:
            additionalQuery = `
            coinLoyaltyBonusState
              spinRewardHistory(type: $type) {
                id
                type
                dateRedeemed
                bonuscode {
                  code
                  type
                  value
                  isRedeemed
                }
                coins {
                  amount
                }
              }
            `;
            break;
        default:
            additionalQuery = `
              points(currentMonthOnly: true)
		      spins
              monthlyRewards {
				id
				isRedeemed
				isRedeemable
				requiredPoints
				type
				bonuscode {
				  code
				  type
				  value
				}
				voucher {
				  amount
				}
				messengerGift {
				  id
				  name
				  price {
					value
					currency
					symbol
				  }
				  imageUrl
				  active
				  vip
				  categoryId
				  translations {
					language
					name
					value
				  }
				}
				freeSpins {
				  amount
				}
			  }
            `;
    }

	const query = `
		query($type: LoyaltyPointsSpinwheelType) {
		  guest {
		    loyaltyPoints {
		      spinwheelActive(type: $type)
		      lastSpinDate(type: $type)
              ${additionalQuery}
		    }
		  }
		}`;

	doVxqlRequest(query, variables).then((result) => callback(result));
}

function doSpinwheelSpin(callback, type) {
    const variables = type ? {type} : {};
	const query = `
	mutation($type: LoyaltyPointsSpinwheelType) {
	guest {
		loyaltyPoints {
			doSpin(type: $type) {
				type
				bonuscode {
					code
					type
					value
				}
				coins {
					amount
				}
				freeSpins {
					amount
				}
				messengerGift {
					imageUrl
					translations {
						language
						value
					}
				}
				voucher {
					amount
				}
			}
		}
	}
	}`;

	doVxqlRequest(query, variables).then((result) => callback(result));
}

function claimCoins(callback) {
	const query = `
	mutation {
        guest {
            loyaltyPoints {
                claimCoinLoyaltyBonus
            }
        }
	}`;

	doVxqlRequest(query, {}).then((result) => callback(result));
}

function getLoyaltyPointsRewardHistory(callback) {
	const query = `
	query {
		guest {
			loyaltyPoints {
			  rewardHistory {
				id
				isRedeemed
				type
				bonuscode {
				  code
				  type
				  value
				}
				voucher {
				  amount
				}
				messengerGift {
				  id
				  name
				  price {
					value
					currency
					symbol
				  }
				  imageUrl
				  active
				  vip
				  categoryId
				  translations {
					language
					name
					value
				  }
				}
				freeSpins {
				  amount
				}
			  }
			}
		  }
    }
	`;

	doVxqlRequest(query).then((result) => callback(result));
}

function redeemLoyaltyPointsReward(id, callback) {
	const query = `
	mutation($id: Int!) {
		guest {
		  loyaltyPoints {
			redeemReward(id: $id)
		  }
		}
	  }
	`;

	doVxqlRequest(query, {id}).then((result) => callback(result));
}

function tryLoyaltyPointsMigrate(callback) {
    const query = `
	mutation {
		guest {
		  loyaltyPoints {
			tryMigrate
		  }
		}
	  }
	`;

    doVxqlRequest(query).then((result) => callback(result));
}

const VxqlStore = assign({}, FluxEventEmitter.prototype, {

	/**
	 * @param {Function} callback
	 */
	addLoadedListener: function(callback) {
		this.on(CHANGE_EVENTS.DailyBonusLoaded, callback);
	},
	/**
	 * @param {Function} callback
	 */
	removeLoadedListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.DailyBonusLoaded, callback);
	},
	getGuestInitData(callback) {
		getGuestInitData(callback);
	},
	initVxEsFreePreviewInit(decline = false) {
		initVxEsFreePreviewInit(decline);
	},
	getUnreadMessagesCount(callback) {
		getUnreadMessagesCount(callback);
	},
	getMessengerGifts(callback) {
		getMessengerGifts(callback);
	},
	getMessengerToyControls(callback) {
		getMessengerToyControls(callback);
	},
	getBoughtGiftsForGuest(callback) {
		getBoughtGiftsForGuest(callback);
	},
	getOnlineModels(amount, callback) {
		getOnlineModels(amount, callback);
	},
	getFilteredModels(amount, filter, callback) {
		getFilteredModels(amount, filter, callback);
	},
	getModels(first, last, offset, order, filter, preset, isV3, onlineFilter) {
		return getModels(first, last, offset, order, filter, preset, isV3, onlineFilter);
	},
	getPreferencedModel(filter, callback) {
		getPreferencedModel(filter, callback);
	},
	getFilteredVideos(amount, filter, callback, order, modelIds, ids) {
		getFilteredVideos(amount, filter, callback, order, modelIds, ids);
	},
	processGlobalSearch(searchPattern, isExtended, callback) {
		processGlobalSearch(searchPattern, isExtended, callback);
	},
	processSuggestions(callback) {
		processSuggestions(callback);
	},
	storeSearchPattern(searchPattern, callback) {
		storeSearchPattern(searchPattern, callback);
	},
	getDailyBounes(callback) {
		getDailyBonuses(null, callback);
	},
	/**
	 * @param {String} date
	 * @param {Function} callback
	 */
	getDailyBounesFromApi:  function(date, callback) {
		getDailyBonuses(date, callback);
	},
	getDailybonusFromStore: function(date) {
		return Object.hasOwn(_dailybonusData, date) ? _dailybonusData[date] : [];
	},
	/**
	 * @param {Boolean} activeIsSpecial
	 * @param {Function} callback
	 */
	getVxqlContestsFromApi:   function(activeIsSpecial, callback) {
		getVxqlContests(activeIsSpecial, callback);
	},
	getVxqlContestsFromStore: function() {
		return _contestsData;
	},
	getBonusModels(callback) {
		getBonusModels(callback);
	},
	/**
	 * @param {Function} callback
	 * @param {Number} first
	 * @param {Number} offset
	 * @param {String|null} dateFrom
	 * @param {String|null} dateTo
	 */
	getConsumedOffers(callback, first = 31, offset = 0, dateFrom = null, dateTo = null) {
		getConsumedOffers(callback, first, offset, dateFrom, dateTo);
	},
	/**
	 * @param {Number} actorId
	 * @returns {Promise}
	 */
	getActorChatRatings(actorId) {
		return getActorChatRatings(actorId);
	},
	/**
	 * @param {Number} actorId
	 * @param {Number} first
	 * @returns {Promise}
	 */
	getActorRecommendedModels(actorId, first = 4, isExtended = false) {
		return getActorRecommendedModels(actorId, first, isExtended);
	},
	sendFeatureFeedback(featureId, rating, text, url, callback) {
		sendFeatureFeedback(featureId, rating, text, url, callback);
	},
	addVideoLike(id, callback) {
		addVideoLike(id, callback);
	},
	addVideoDislike(id, callback) {
		addVideoDislike(id, callback);
	},
	addAlbumView(id) {
		addAlbumView(id);
	},
	addGalleryLike(id, callback) {
		addGalleryLike(id, callback);
	},
	addGalleryDislike(id, callback) {
		addGalleryDislike(id, callback);
	},
	getGuestVideoTime(chatId, callback) {
		getGuestVideoTime(chatId, callback);
	},
	processActorSearch(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
		processActorSearch(searchPattern, callback, first, offset, order, filter);
	},
	processVideoSearch(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
		processVideoSearch(searchPattern, callback, first, offset, order, filter);
	},
	processGallerySearch(searchPattern, callback, first = 15, offset = 0, order = null, filter = null) {
		processGallerySearch(searchPattern, callback, first, offset, order, filter);
	},
	processMediathekSearch(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
		processMediathekSearch(searchPattern, callback, first, offset, order, filter);
	},
	processBlogSearch(searchPattern, callback, first = 15, offset = 0, order = null, filter = null) {
		processBlogSearch(searchPattern, callback, first, offset, order, filter);
	},
	getFilteredActorVideos(
		actorId,
		count,
		offset,
		orders,
		includeGuestRating,
		tags,
		onlyFreeContent,
		fetchAllVideosCount,
		onlyVXSelectContent,
		onlyClassicContent
	) {
		return getFilteredActorVideos(actorId, count, offset, orders, includeGuestRating, tags, onlyFreeContent, fetchAllVideosCount, onlyVXSelectContent, onlyClassicContent);
	},
	getActorVideoTags(actorId) {
		return getActorVideoTags(actorId);
	},
	getVideoRecommendationsByActorId(actorId, countFromSameActor, countFromDifferentActor) {
		return getVideoRecommendationsByActorId(actorId, countFromSameActor, countFromDifferentActor);
	},
	getVideoRecommendationsByAlbumId(albumId, countFromSameActor, countFromDifferentActor) {
		return getVideoRecommendationsByAlbumId(albumId, countFromSameActor, countFromDifferentActor);
	},
	getConversionChatActors(first = 30, offset = 0) {
		return getConversionChatActors(first, offset);
	},

	getUserActivityData(activityId, callback) {
		getUserActivityData(activityId, callback);
	},

	setUserActivityReward(activityId, rewardId, callback) {
		setUserActivityReward(activityId, rewardId, callback);
	},

	getBonusCode(code, callback) {
		getBonusCode(code, callback);
	},

	getRemainingBonusCodes(callback) {
		getRemainingBonusCodes(callback);
	},

	getEasterEvent(eventId, callback) {
		getEasterEvent(eventId, callback);
	},

	getProfileLiveShowTicket(actorId, callback) {
		getProfileLiveShowTicket(actorId, callback);
	},

	buyChatTicket(ticket, creationType, callback) {
		buyChatTicket(ticket, creationType, callback);
	},

    removeChatTicket(ticket, callback) {
        removeChatTicket(ticket, callback);
	},

	getVip30ValueHistory(callback) {
		getVip30ValueHistory(callback);
	},

	loadBanner(mobile, routeName, routeArgs, callback) {
		loadBanner(mobile, routeName, routeArgs, callback);
	},

	getMessengerContent: function(first, offset, filter, order, callback) {
		getMessengerContent(first, offset, filter, order, callback);
	},

	getLateNightShow(callback) {
		getLateNightShow(callback);
	},

	getCurrentProgram(premium, callback) {
		getCurrentProgram(premium, callback);
	},

	getCamShows({endDateFrom, order, startDateTo, first, internal, callback}) {
		getCamShows({endDateFrom, order, startDateTo, first, internal, callback});
	},

	/**
	 * @param actorId
	 */
	getActorHeatMaps(actorId) {
		return getActorHeatMaps(actorId);
	},

	/**
	 * @param {Int} albumId
	 * @param {Array} tags
	 * @param {function} callback
	 */
	addTagToAlbum(albumId, tags, callback) {
		addTagToAlbum(albumId, tags, callback);
	},

	getCollection(order, first, callback) {
		return getCollection(order, first, callback);
	},

	getCollectionsV2(args, callback) {
		return getCollectionsV2(args, callback);
	},

	async getActorsByCollectionSlug(collectionSlug, first = 15, offset = 0, filter) {
		return getActorsByCollectionSlug(collectionSlug, first, offset, filter);
	},

	async getActorsTotalByCollectionSlug(collectionSlug, first = 15, offset = 0, filter) {
		return getActorsTotalByCollectionSlug(collectionSlug, first, offset, filter);
	},

	/**
	 * @param filter
	 * @param order
	 * @param count
	 * @param offset
	 */
	getVideosFromVXQL(filter, order, count, offset) {
		return getVideosFromVXQL(filter, order, count, offset);
	},

	/**
	 * @param filter
	 * @param order
	 * @param count
	 * @param offset
	 */
	getPhotoAlbumsFromVXQL(filter, order, count, offset) {
		return getPhotoAlbumsFromVXQL(filter, order, count, offset);
	},

	/**
	 * @param count
	 * @param offset
	 */
	getTVSeriesFromVXQL(count, offset) {
		return getTVSeriesFromVXQL(count, offset);
	},

	getTVMediathekVideoRecommendationsByHashIdFromVXQL(hashId, count) {
		return getTVMediathekVideoRecommendationsByHashIdFromVXQL(hashId, count);
	},

	getMediathekVideosFromVXQL(filter, count, offset) {
		return getMediathekVideosFromVXQL(filter, count, offset);
	},

	/**
	 *
	 * @param {function} callback
	 * @param {number} actorId
	 */
	loadFreeChatDuration(callback, actorId) {
		loadFreeChatDuration(callback, actorId);
	},

	getChannels(callback, blocked) {
		getChannels(callback, blocked);
	},

	/**
	 *
	 * @param {function} callback
	 * @param {boolean} feedOnly
	 */
	getFavoriteModels(callback, feedOnly = false) {
		getFavoriteModels(callback, feedOnly);
	},

	/**
	 *
	 * @param first
	 * @param feedOnly
	 * @param callback
	 */
	getRecommendedModels(first, feedOnly, callback) {
		getRecommendedModels(first, feedOnly, callback);
	},

	/**
	 *
	 * @param {function} callback
	 */
	getBenefits(callback) {
		getBenefits(callback);
	},

	/**
	 *
	 * @param {function} callback
	 */
	getMyVisitXSidebarData(callback) {
		getMyVisitXSidebarData(callback);
	},

	/**
	 * @param {Number} actorId
	 * @param {String} imageSize
	 * @param {Boolean} placeholder
	 * @param {String} durationFormat
	 * @return {Promise}
	 */
	loadActorInterviews(
		actorId,
		imageSize      = Constants.Interviews.IMAGE_SIZE_720,
		placeholder    = true,
		durationFormat = Constants.Interviews.DURATION_SEC
	) {
		return _loadActorInterviews(actorId, imageSize, placeholder, durationFormat);
	},

	/**
	 * @param {Number} first
	 * @param {String} order
	 * @return {Promise}
	 */
	load365InteractiveContent(first, order) {
		const query = `
			query DailyBonusInteractive(
				$first: Int
				$order: InteractiveOrderEnum
			) {
				dailyBonus {
					interactive(first: $first, order: $order) {
						title
						description
						isActive
						until
						linkVX(internal: true)
						previewUrl
					}
				}
			}
		`;

		return doVxqlRequest(query, {first, order});
	},
	/**
	 * @param {String} date
	 * @param {Boolean} isMobile
	 * @return {Promise<unknown>}
	 */
	loadEventConfig(date, isMobile) {
		// check saved promise first
		if (null !== eventConfigPromise) {
			return eventConfigPromise;
		}

		const query = `
			query DailyBonusEventConfig($date: Date, $isMobile: Boolean) {
				dailyBonus {
					event(date: $date) {
						name
						className
						homepageBlockBg(mobile: $isMobile)
						headerBg(mobile: $isMobile)
						headerTitle(mobile: $isMobile)
						headerDescription(mobile: $isMobile)
						interactiveBg(mobile: $isMobile)
						historyBg(mobile: $isMobile)
						redirect
						redirectUrl
						showMysteriousTomorrowOffer
						hidesDailyBonusPage
						headerBgLogo(mobile: $isMobile)
					}
				}
			}
		`;

		// save promise instance
		eventConfigPromise = doVxqlRequest(query, {date, isMobile});

		// and return it
		return eventConfigPromise;
	},

	addLiveReaction(callback, referenceType, referenceId, imageId, message, data) {
		addLiveReaction(callback, referenceType, referenceId, imageId, message, data);
	},

	startAnonymousVoicecallChat(callback, actorId, accessToken) {
		startAnonymousVoicecallChat(callback, actorId, accessToken);
	},

	getVideoStars(first = 100) {
		return getVideoStars(first);
	},

	claimActorGiftCalendarReward(actorId, qmDay) {
		return claimActorGiftCalendarReward(actorId, qmDay);
	},

	touchChannel(actorId) {
		touchChannel(actorId);
	},

	getWelcomeClips(first, fsk, callback) {
		getWelcomeClips(first, fsk, callback);
	},

	getLegalTexts(filter, first, offset, callback) {
		getLegalTexts(filter, first, offset, callback);
	},

	getSeo(tags, callback) {
		getSeo(tags, callback);
	},

	/* Spinwheel */
	getSpinwheelInfo(callback, type) {
		getSpinwheelInfo(callback, type);
	},

	doSpinwheelSpin(callback, type) {
		doSpinwheelSpin(callback, type);
	},

    claimCoins(callback) {
		claimCoins(callback);
	},

	getLoyaltyPointsRewardHistory(callback) {
		getLoyaltyPointsRewardHistory(callback);
	},

	redeemLoyaltyPointsReward(id, callback) {
		redeemLoyaltyPointsReward(id, callback);
	},

    tryLoyaltyPointsMigrate(callback){
        tryLoyaltyPointsMigrate(callback);
    }
});

VxqlStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case DailyBonusActionTypes.RELOAD:
			// empty caches
			_dailybonusData = {};

			// fetch data
			VxqlStore.getDailyBounes(function(result) {
				VxqlStore.emit(CHANGE_EVENTS.DailyBonusLoaded, result);
			});
			break;

		case DailyBonusActionTypes.LOAD_INTERACTIVE_CONTENT:
			VxqlStore
				.load365InteractiveContent(action.limit, action.order)
				.then((result) => {
					if (result.data && result.data.dailyBonus && result.data.dailyBonus.interactive) {
						DailyBonusActionCreator.loadedInteractiveContent(action.limit, action.order, result.data.dailyBonus.interactive);
					}
				});
			break;

		case DailyBonusActionTypes.LOAD_EVENT_CONFIG:
			VxqlStore
				.loadEventConfig(action.date, action.isMobile)
				.then(result => {
					if (result.data && result.data.dailyBonus && result.data.dailyBonus.event) {
						// dispatch
						DailyBonusActionCreator.loadedEventConfig(action.date, action.isMobile, result.data.dailyBonus.event);
					}
					// release promise
					eventConfigPromise = null;
				});
			break;

		case Constants.ActionTypes.Actor.LOAD_CHAT_RATINGS:
			VxqlStore.getActorChatRatings(action.actorId).then((result) => {
				if (result && result.data && result.data.model && result.data.model.chatRatings) {
					ActorActionCreator.actorChatRatingsLoaded(result.data.model.chatRatings, action.actorId);
				}
			});
			break;

		case Constants.ActionTypes.Actor.LOAD_RECOMMENDED_MODELS:
			VxqlStore.getActorRecommendedModels(action.actorId, action.first, action.isExtended)
				.then((vxql) => {
					if (vxql.data
						&& vxql.data.model
						&& vxql.data.model.recommendations
						&& vxql.data.model.recommendations.models
					) {
						ActorActionCreator.modelRecommendationsLoaded(action.actorId, action.first, vxql.data.model.recommendations.models);
					}
				});
			break;

		case Constants.ActionTypes.Actor.LOAD_INTERVIEWS:
			VxqlStore
				.loadActorInterviews(action.actorId, action.imageSize, action.placeholder, action.durationFormat)
				.then(function(interviews) {
					if (interviews.data.model.interviews) {
						ActorActionCreator.actorInterviewsLoaded(
							interviews.data.model.interviews,
							action.actorId,
							action.imageSize,
							action.placeholder,
							action.durationFormat
						);
					}
				});
			break;

		default:
	}

});


export default VxqlStore;

export {doVxqlRequest};
